import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import newslogo1 from "../assets/news-logo01.png";
import newslogo2 from "../assets/news-logo02.png";
import newslogo3 from "../assets/news-logo03.png";
import newslogo4 from "../assets/news-logo04.png";
import newslogo5 from "../assets/news-logo05.png";
import newslogo6 from "../assets/news-logo06.png";
import star from "../assets/star.png";
import review from "../assets/Frame 518 (2).webp";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import testimonial from "../assets/review.png";
import user1 from "../assets/user-1.jpeg";
import user2 from "../assets/user-2.jpeg";
import user3 from "../assets/user-3.jpeg";
import user4 from "../assets/user-4.jpeg";
import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/petrol-pump.svg";
import groceryImage from "../assets/grocery.svg";
import rentImage from "../assets/rent.svg";
import shoppingImage from "../assets/card.svg";
import Accordion from "../component/Accordion";

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  function gotoTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
  const accordionData = [
    {
      id: "1",
      title: "1. Can I really reduce my payments by 50%?",
      content: `Yes, we’ve already helped 145,769 people save hundreds per month. We use an AI algorithm to reduce your debt by the most amount possible`,
    },
    {
      id: "2",
      title: "2. Why would they be ok reducing my debt?",
      content: `Because they know that if you file bankruptcy, they may get nothing at all. Due to the possibility of taking a loss, creditors are ok restructuring how much you owe, especially once they recognize the financial hardships you are facing. In other words, your creditors would rather accept some v.s nothing at all`,
    },
    {
      id: "3",
      title: "3. How quickly can I get my debt reduced?",
      content: `After you fill out the above form, we’ll see if you qualify. If you qualify you’ll see a phone number you can call. On the call we’ll be able to reduce what you owe immediately.`,
    },
  ];

  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#"></a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          <div className="card-section">
            <div className="container">
              <div className="row">
                <div className="custom-container">
                  <div className="icon-pack">
                    <div className="headline-back">
                      <h2>Mentioned On</h2>
                    </div>
                    <div className="logo-icon-pack">
                      <img src={newslogo1}></img>
                      <img src={newslogo2}></img>
                      <img src={newslogo3}></img>
                      <img src={newslogo4}></img>
                      <img src={newslogo5}></img>
                      <img src={newslogo6}></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section2">
            <div className="container">
              <div className="row">
                <div className="section2-headline-container">
                  <div className="section2-headline">
                    <h1>
                      Transforming Lives: The Impact of the Debt Reduction
                    </h1>
                    <p>
                      The New Debt Reduction Plan is designed to help those
                      struggling with bills. If inflation has impacted your
                      finances, this is the chance to cut up to $80,000 from
                      your debt. Act now and call our team for financial relief.
                    </p>
                  </div>
                </div>
                <div className="section2-stat-counter">
                  <div className="statCounter">
                    <div className="statCounterImage"></div>
                    <div className="statCounterStats">
                      <h3>$43 Million</h3>
                      <p>
                        The ripple effect of financial empowerment is clear,
                        with substantial debt relief provided to households
                        across the nation.
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterImage"></div>
                    <div className="statCounterStats">
                      <h3>250,000+</h3>
                      <p>
                        Many lives have been touched, with more families joining
                        every day to avail the comprehensive benefits.
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterImage"></div>
                    <div className="statCounterStats">
                      <h3>1 in 3</h3>
                      <p>
                        Reflecting the widespread need, a third of the
                        population qualifies for this life-enhancing program,
                        ensuring no one is left behind.
                      </p>
                    </div>
                  </div>
                  <div className="statCounter">
                    <div className="statCounterImage"></div>
                    <div className="statCounterStats">
                      <h3>$482 Seats</h3>
                      <p>
                        With limited spots remaining, urgency meets opportunity
                        for those aiming to harness the program's full
                        potential.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="container">
              <div className="row">
                <div className="section2-headline-container1">
                  <div className="section2-headline1">
                    <h1>Why was this debt relief program created?</h1>
                    <p>
                      Since the pandemic, prices have surged, increasing the
                      cost of essentials like food and utilities. This new
                      program aims to support families facing these financial
                      challenges and provide relief from the burden of
                      inflation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Satisfied Citizens Share Their Stories!</h1>
                </div>
                <div className="testimonial-content-holder">
                  <div className="testimonial-content text">
                    <p>
                      We strive to offer you the best service, as evidenced by
                      our Trustpilot star rating.
                    </p>
                  </div>
                  <div className="testimonial-content-image">
                    <img src={testimonial} alt="" />
                  </div>
                </div>

                <div className="testimonial-list">
                  <div className="testimonial-row">
                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user1} className="userimg"></img>
                          <p>
                            The Debt Relief Program provided exceptional
                            service. They guided me through every step of the
                            debt reduction process and helped relieve a
                            significant financial burden. I can’t thank them
                            enough for their support.
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            Floyd Miles | <span>Citizen of USA</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user4} className="userimg"></img>
                          <p>
                            Thanks to the Debt Reduction Program, I can now
                            relax knowing my debt is under control. The team was
                            professional and attentive, providing the peace of
                            mind I needed.
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            Olivia Martinez | <span>Citizen of USA</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-row">
                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user2} className="userimg"></img>
                          <p>
                            I received personalized and effective support from
                            the Debt Reduction Program. The reduction in my debt
                            was beyond my expectations, and I feel much more
                            confident about my financial future.
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            Michael Anderson | <span>Citizen of USA</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div className="testimonial-item">
                      <div className="testimonial-inner">
                        <div className="testimonial-top-rating">
                          <div className="star-rating">
                            <img src={star} alt="" />
                          </div>
                          <div className="review">
                            <img src={review} alt="" />
                          </div>
                        </div>
                        <div className="testimonial-top">
                          <img src={user3} className="userimg"></img>
                          <p>
                            The Debt Reduction Program did a fantastic job
                            helping me reduce my debt quickly and effectively.
                            I’m very thankful for their commitment and the care
                            they showed throughout the process.
                          </p>
                        </div>
                        <div className="testimonial-bottom">
                          <h3>
                            David Wilson | <span>California, LA</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section3">
            <div className="container">
              <div className="row">
                <div className="faq">
                  <h2>FAQs</h2>
                  <p>
                    We’ve helped remove $200,721,032 in credit card debt &
                    counting.
                  </p>
                </div>

                <div className="accordion">
                  {accordionData.map(({ title, content, id }) => (
                    <Accordion key={id} title={title} content={content} />
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>
                      © Copyright {window.domain_settings.websiteTitle} 2023
                    </p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
