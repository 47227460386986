import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGeneratorQuery } from "wecall-config-lib";
import card_in_hamd from "../assets/hero.svg";
import GetRingbaNumber from "../component/GetRingbaNumber";
import { ROUTES, tenk, sessionStorageRoutes } from "../component/Constant";

function Income({ headline }) {
  const generatorQuery = useGeneratorQuery();

  const setValues = (value) => {
    sessionStorage.setItem(tenk, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [tenk]: value });
  };

  const navigate = useNavigate();
  const externalRedirectLink = window.domain_settings.medicareMedicAidYesLink;

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(sessionStorageRoutes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(sessionStorageRoutes.income, true);
  }, []);
  return (
    <>
      <GetRingbaNumber />
      <div className="question-wrapper">
        <div className="headline-wrapper">
          <h1>{headline}</h1>
        </div>
        <form method="POST" className="question-item">
          <div className="question">
            <h2>Do you have over $10,000 in credit card debt?</h2>

            <div className="option-renderer-wrapper">
              <div className="options-container">
                <input
                  type="radio"
                  id="health-yes"
                  name={tenk}
                  value="yes"
                  onChange={() => {
                    setValues("yes");
                    navigate("../" + ROUTES.medicareMedicaid, {
                      search: generatorQuery.get(),
                    });
                  }}
                ></input>
                <label className="option-button" htmlFor="health-yes">
                  Yes
                </label>
              </div>

              <div className="options-container">
                <input
                  type="radio"
                  id="health-no"
                  name={tenk}
                  value="no"
                  onChange={() => {
                    setValues("no");
                    const newWindow = window.open(
                      externalRedirectLink,
                      "_blank"
                    );
                    newWindow.opener = null;
                    newWindow.location.href = externalRedirectLink;
                    newWindow.addEventListener("load", () => {
                      newWindow.history.pushState(
                        null,
                        null,
                        newWindow.location.href
                      );
                      newWindow.history.pushState(
                        null,
                        null,
                        newWindow.location.href
                      );
                    });
                  }}
                ></input>

                <label className="option-button" htmlFor="health-no">
                  No
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="card-container">
        <div className="mainbg">
          <img src={card_in_hamd} alt=""></img>
        </div>
      </div>
    </>
  );
}

export default Income;
